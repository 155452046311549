import React, { useState, useEffect } from 'react';
import useTranslations from "../utils/useTranslations";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
} from 'reactstrap';
import "flickity/dist/flickity.min.css";
import { getCountry } from "../utils";
import _ from "lodash";

import webHero1x from '../images/homepage/carousel-hero-web.png';
import webHero2x from '../images/homepage/carousel-hero-web-2x.png';
import classicHero1x from '../images/homepage/carousel-hero-classic.png';
import classicHero2x from '../images/homepage/carousel-hero-classic-2x.png';
import androidHero1x from '../images/homepage/carousel-hero-android.png';
import androidHero2x from '../images/homepage/carousel-hero-android-2x.png';
import liteHero1x from '../images/homepage/carousel-hero-lite.png';
import liteHero2x from '../images/homepage/carousel-hero-lite-2x.png';

import btwebIcon from '../images/homepage/carousel-bt-web-icon.svg';
import btClassicIcon from '../images/homepage/carousel-classic-icon.svg';
import btAndroidIcon from '../images/homepage/carousel-android-icon.svg';
import utLiteIcon from '../images/homepage/carousel-lite-icon.svg';

import btwebBlackLogo from '../images/logos/web-dark.svg';
import btwebWhiteLogo from '../images/logos/web-white.svg';
import classicWhiteLogo from '../images/logos/classic-white.svg';
import classicBlackLogo from '../images/logos/classic-black.svg';
import androidWhiteLogo from '../images/logos/logo-android-white.svg';
import androidBlackLogo from '../images/logos/logo-android-dark.svg';
import liteWhiteLogo from '../images/logos/lite-white.svg';
import liteBlackLogo from '../images/logos/lite-black.svg';

import rightWhiteArrowIcon from '../images/icons/right-arrow-white-thin.svg';
// import rightWBlackArrowIcon from '../images/icons/right-arrow-black-thin.svg';
import ArrowWhiteCircle from '../images/icons/arrow-white-circle.svg';
// import ArrowBlackCircle from '../images/icons/arrow-black-circle.svg';
// import AppleIcon from '../images/icons/apple-icon.svg';
import prevCarouselBlacklcon from '../images/homepage/carousel-prev-black.svg';
import nextCarouselBlacklcon from '../images/homepage/carousel-next-black.svg';
import prevCarouselWhitelcon from '../images/homepage/carousel-prev.svg';
import nextCarouselWhitelcon from '../images/homepage/carousel-next.svg';

import carouselBlackLightning from '../images/homepage/carousel-lite-lightning-dark.svg';
import carouselWhiteLightning from '../images/homepage/carousel-lite-lightning-white.svg';

import Button from "./button";

const Flickity = typeof window !== "undefined" ? require("react-flickity-component"): () => null;
const FlickityNav = typeof window !== "undefined" ? require("flickity-as-nav-for"): () => null;

const items = {
  btw: {
    product: 'bittorrent-web',
    productLogo: {white: btwebWhiteLogo, black: btwebBlackLogo },
    heroImg: [webHero1x, webHero2x],
    header: 'webHeader',
    subheader: 'webSubheader',
    button1: 'Free Download',
    button1Link: {win: "/products/win/bittorrent-web-compare/", mac: "/products/mac/bittorrent-web-compare/" },
    button2: 'Learn More',
    button2Link: {win: "/products/win/bittorrent-web-free/", mac: "/products/mac/bittorrent-web-free/" },
    productTabLabel: ["Bittorrent", "Web"],
    productTabIcon: btwebIcon,
    productTabIconWidth: "71",
    // smallText: "Also available on",
    // smallTextIcon: AppleIcon,
  },
  btc: {
    product: 'bittorrent-classic',
    productLogo: {white: classicWhiteLogo, black: classicBlackLogo },
    heroImg: [classicHero1x, classicHero2x],
    header: 'classicHeader',
    subheader: 'classicSubheader',
    button1: 'Free Download',
    button1Link: "/products/win/bittorrent-classic-compare/",
    button2: 'Learn More',
    button2Link: "/products/win/bittorrent-classic-free/",
    productTabLabel: ["Bittorrent", "Classic"],
    productTabIcon: btClassicIcon,
    productTabIconWidth: "75",
    // smallText: "Not available for",
    // smallTextIcon: AppleIcon,
  },
  bta: {
    product: 'bittorrent-android',
    productLogo: {white: androidWhiteLogo, black: androidBlackLogo },
    heroImg: [androidHero1x, androidHero2x],
    header: 'androidHeader',
    subheader: 'androidSubheader',
    button1: 'Free Download',
    button1Link: "/products/android/bittorrent-android-free/#comparison-panels",
    button2: 'Learn More',
    button2Link: "/products/android/bittorrent-android-free/",
    productTabLabel: ["Bittorrent", "Android"],
    productTabIcon: btAndroidIcon,
    productTabIconWidth: "36",
  },
  utl: {
    product: 'utorrent-lite',
    productLogo: { white: liteWhiteLogo, black: liteBlackLogo },
    heroImg: [liteHero1x, liteHero2x],
    header: 'liteHeader',
    subheader: 'liteSubheader',
    button1: ["Launch now on ${liteLink}", {"liteLink": "μTorrent Lite (beta)" }],
    button1Link: "https://lite.utorrent.com?utm_source=bts&utm_medium=carousel&utm_campaign=beta_promo&utm_id=1",
    productTabLabel: ["µTorrent", "Lite"],
    productTabIcon: utLiteIcon,
    details: 'compatibilityLite',
    isNew: true,
    isBeta: true,
  },
};

const Slide = ({name, t, index, isMac}) => {
  const isLite = name === 'utl';
  const item = items[name];
  const isBlackBg = index % 2 === 0 || isLite;
  let downloadLink = '';
  let learnMoreLink = '';
  
  if(item.button1Link.mac || item.button1Link.win) {
    downloadLink = isMac ? item.button1Link.mac :  item.button1Link.win;
  } else {
    downloadLink = item.button1Link;
  }

  if(item.button2) {
    if(item.button2Link.mac || item.button2Link.win) {
      learnMoreLink = isMac ? item.button2Link.mac :  item.button2Link.win;
    } else {
      learnMoreLink = item.button2Link;
    }
  }

  return(
    <div className={`container-fluid carousel-item-product ${isBlackBg ? "": "grainy-background text-dark"}`} >
    {isLite && <img src={isBlackBg ? carouselBlackLightning : carouselWhiteLightning} className="carousel-lite-lightning" width="50" alt="lightning" />}
    <div className="row">
      <div className={`carousel-left-container col-lg-6 px-0 ${item.details ? "py-2": "py-5"}`}>
        <div className="inner px-2">
          <div className="d-flex carousel-logo-container">
            <div style={{ maxWidth: "100%" }}>
              <img className="carousel-product-logo" src={isBlackBg ? item.productLogo.white : item.productLogo.black} alt={item.product} style={{ maxWidth: "100%" }}/>
            </div>
            {item.isBeta && <span className="beta-text">({t("beta")})</span>}
            {item.isNew && <div className="carousel-new-badge ml-2 px-2 align-self-center">{t("NEW")}</div>}
          </div>
          <h1 className="carousel-section-header-title mt-4">{t(item.header)}</h1>
          <p className="carousel-section-header-subheader">{t(item.subheader)}
            {/* {!isLite && <a className={`font-weight-bold ${isBlackBg ? "text-white" : " text-body"}`} href={item.button2Link}> {t('Learn More ')}<img src={isBlackBg ? rightWhiteArrowIcon : rightWBlackArrowIcon} alt="right-arrow" /></a>} */}
          </p>
          {item.details && 
            <div className={`carousel-section-details mobile ${isBlackBg ? "black-bg" : " white-bg"}`}>
              <p className="m-0 p-0">{t(item.details)}</p>
            </div>
          }
          <div className="cta-container">
            <div className="cta-btns">
              <div className="d-flex flex-column align-items-center">
                {item.button1 && !isLite &&
                  <Button id="carousel-products-page-download-btn" href={downloadLink} className="btn purple-btn align-items-center">
                    {t(item.button1)}
                  </Button>}
                {item.button1 && isLite &&
                  <Button id="carousel-products-page-launch-btn" href={downloadLink} className="btn purple-btn align-items-center">
                    {t(item.button1[0], item.button1[1] && item.button1[1])}
                    <img className="ml-1" src={ArrowWhiteCircle}  alt="arrow"/>
                  </Button>}
                  {/* <div className="small-text mt-3 align-items-center d-flex">{item.smallText && <span>{t(item.smallText)} {item.smallTextIcon && <img className="mb-1" src={item.smallTextIcon} alt="apple icon" />}</span>}</div> */}
              </div>
              {item.button2 && <Button id="carousel-products-page-learn-more-btn" href={learnMoreLink} className={`btn bg-transparent ${isBlackBg ? "border black-bg" : "white-bg"}`}>{t(item.button2)}</Button>}
            </div>
            {item.details && 
              <div className={`carousel-section-details desktop ${isBlackBg ? "black-bg" : " white-bg"}`}>
                <p className="m-0 p-0">{t(item.details)}</p>
              </div>
            }
          </div>
        </div>
      </div>
        <div className={`carousel-right-container ${isLite ? "lite-hero" : " people-hero"} ${item.product}`}>
          <img src={item.heroImg[0]} srcSet={`${item.heroImg[1]} 2x`} alt="bittorrent web" className={`carousel-hero-image ${item.product}`} />
          {!isLite && <div className={`carousel-mobile-image-shadow${isBlackBg ? "-black" : "-white"}`}></div>}
        </div>
    </div>
  </div>
  )
}

function CarouselLayout(props) {
  const countryCode = getCountry();
  const isMac = typeof window !== 'undefined' ? window.gatsbyIsMac : false;
  const isMobile = typeof window !== 'undefined' ? window.gatsbyIsMobile : false;
  const isIpad = typeof window !== 'undefined' ? window.gatsbyIsIpad : false;
  const isCrOS = typeof window !== 'undefined' ? window.gatsbyIsCrOS : false;
  const isAndroid = typeof window !== 'undefined' ? window.gatsbyIsAndroid : false;
  const isiOS = typeof window !== 'undefined' ? window.gatsbyIsiOS : false;

  const { text } = props;
  const t = useTranslations(text);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  // default orderList
  const defaultOrder = ['btw', 'btc', 'bta'];
  const [orderedList, setOrderedList] = useState(defaultOrder);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === orderedList.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? orderedList.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  
  useEffect(() => {
    let newOrder = [];
    // Lite will be first slide on all russian, all iOS/ipad, all chrome os, all android
    if (isAndroid) {
      newOrder = ['bta', 'btw', 'btc'];
    } else {
      newOrder = ['btw', 'btc', 'bta'];
    }

    if (!_.isEqual(newOrder, orderedList)) {
      setOrderedList(newOrder);
    }
  }, [countryCode, isMac, isMobile, isIpad, isiOS, isCrOS, isAndroid]);

  const desktopSlides = orderedList.map((key, index) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={index}
      >
        <Slide index={index} name={key} t={t} isMac={isMac}/>
      </CarouselItem> 
    );
  });

  const mobileSlides = orderedList.map((key, index) => {
    return (
      <Slide key={index} index={index} name={key} t={t} isMac={isMac}/>
    );
  });
  
  const productNavTabs = orderedList.map((key, index) => {
    return(
      <div key={items[key].product} id={`carousel-products-page-${items[key].product}-tab`} className={`carousel-product-tab ${activeIndex === index ? "carousel-active-tab" : ""}`}  onClick={()=>goToIndex(index)}>
        {items[key].isNew && <div className="carousel-new-badge position-absolute m-2 px-2">{t("NEW")}</div>}
        <img className="carousel-tab-product-icon img-fluid" src={items[key].productTabIcon} alt={items[key].productTabLabel.join(' ')} width={items[key].productTabIconWidth} />
        <p className="carousel-tab-product-name">
          <span className="font-weight-bold bt-font">{items[key].productTabLabel[0]} </span>{items[key].productTabLabel[1]} 
        </p>
      </div>
    )
  })
  
  const allProductsBtn = 
  <div className="product-slider-right-container d-flex align-items-center"> 
    <div className="carousel-all-products-btn">
      <Button id="carousel-products-page-all-products-btn" href="/products/" className="btn bg-transparent">
        <span>{t("All Products")}</span>
        <img src={rightWhiteArrowIcon} alt="white-arrow" className="ml-2" />
      </Button>
    </div>
  </div>

  return (
    <div className="carousel-home-page" data-pause="hover">
      <div className="carousel-desktop">
        <Carousel
          activeIndex={activeIndex}
          next={next}
          previous={previous}
          ride="carousel"
          interval={5000}
          className="carousel-container"
        >
          <ol className="carousel-indicators homepage-carousel-indicators">
            <div className="homepage-carousel-indicators-inner p-0 d-flex">
              {orderedList.map((key, index) => {
                return (
                  <div key={items[key].product} className="indicator-wrapper" onClick={() => goToIndex(index)} >
                    <li className={`${activeIndex === index ? "active" : ""}`}></li>
                  </div>
                )
              })}
            </div>
          </ol>
          {desktopSlides}
          <CarouselControl
            id="carousel-products-page-prev-btn"
            direction="prev"
            onClickHandler={previous}
            className={`${activeIndex > 0 ? "active" : ""}`}
          />
          <CarouselControl
            id="carousel-products-page-next-btn"
            direction="next"
            onClickHandler={next}
            className={`${activeIndex < orderedList.length-1 ? "active" : ""}`}
          />
        </Carousel>
        <hr className="carousel-divider" style={{ margin: "0" , borderTop:"1px solid white" }} />
        <div className="carousel-product-slider text-center">
          <div className="container-fluid">
            <div className="row justify-content-between flex-nowrap">
              <div className="product-slider-left-container d-flex">
                {productNavTabs}
              </div>
              {allProductsBtn}
            </div>
          </div>
        </div>
      </div>
      {
        typeof window !== 'undefined' && 
        <div className="carousel-mobile">
          <Flickity className="carousel-main" 
            options={{
              contain: true,
              pageDots: false,
              wrapAround: true,
              prevNextButtons: false,
              asNavFor: ".carousel-nav",
              reloadOnUpdate: true,
              static: true,
            }}>
            {mobileSlides}
          </Flickity>
          <Flickity
            className="carousel-nav"
            options={{
              contain: true,
              pageDots: false,
              wrapAround: true,
              autoPlay: 5000,
              prevNextButtons: false,
              reloadOnUpdate: true,
              static: true,
            }}
          >
            {productNavTabs}
          </Flickity>
          {allProductsBtn}
        </div>
       }
    </div>
  );
}




export default CarouselLayout;
