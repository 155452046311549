import React from 'react';
import CarouselLayout from '../components/carouselLayout';

const Index = (props) => {
  const { text } = props.pageContext;
  return (
    <>
      <CarouselLayout text={text} />
    </>
  )
}

export default Index;
// Take Simplex Out
// const [showSimplexNotification, setShowSimplexNotification] = useState(false);
// const SimplexAllowedGeos = ['BR', 'IN', 'KR'];
// let timerTimeout = undefined;
// const countryCode = getCountry();

// const closeSimplexNotification = (event) => {
//   event.stopPropagation();
//   setShowSimplexNotification(false);
// }

// const endNotification = () => {
//   clearTimeout(timerTimeout);
//   timerTimeout = setTimeout(() => {
//     setShowSimplexNotification(false);
//   }, 30000);
// }

// useEffect(() => {
//   //Simplex Notification
//   if(SimplexAllowedGeos.includes(countryCode)){
//     if(localStorage.getItem('simplexNotificationState') != 'shown'){
//       setShowSimplexNotification(true);
//       endNotification();
//       localStorage.setItem('simplexNotificationState','shown');
//     }
//   }
// }, [countryCode]);
// <CSSTransition             
//       in={showSimplexNotification} 
//       timeout={3100}
//       classNames="simplex-pop-notification"
//       unmountOnExit
//     >
//       <div className="simplex-notification-outer-container text-white">
//         <SimplexNotification 
//           onClose={() => setShowSimplexNotification(false)} 
//           closeSimplexNotification={closeSimplexNotification}
//           indexText={text}
//         />
//       </div>
//     </CSSTransition>